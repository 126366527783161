export enum OrderStateEnum {
  IN_CAPTURE = 'IN_CAPTURE',
  IN_INCOMING_INSPECTION = 'IN_INCOMING_INSPECTION',
  REQUEST_IN_INCOMING_INSPECTION = 'REQUEST_IN_INCOMING_INSPECTION',
  IN_PROCESSING = 'IN_PROCESSING',
  REQUEST_IN_PROCESSING = 'REQUEST_IN_PROCESSING',
  IN_RELEASE = 'IN_RELEASE',
  REQUEST_IN_RELEASE = 'REQUEST_IN_RELEASE',
  RELEASED = 'RELEASED',
  COMPLETED = 'COMPLETED',
}
